import { Router } from "@reach/router";
import { ErrorBoundary } from "@sentry/react";
import { StateMachineProvider, createStore } from "little-state-machine";
import { Suspense, lazy } from "react";

import LoaderPaymentLink from "components/Loader/LoaderPaymentLink";

// Stylesheets
import "assets/sass/main-payment-link.scss";

// Pages
const Pay = lazy(() => import("./Payment/Pay"));
const PayStep2 = lazy(() => import("./Payment/Pay/Steps/Step2"));
const PayStep3 = lazy(() => import("./Payment/Pay/Steps/Step3"));
const PaySuccess = lazy(() => import("./Payment/PaySuccess"));
const PayProcessing = lazy(() => import("./Payment/PayProcessing"));
const NotFound = lazy(() => import("./NotFound"));
const Inactive = lazy(() => import("./Inactive"));

createStore({
  globalConfig: null,
  link: null,
  previousCharge: null,
  paymentDetails: {
    amount: 0,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    paymentPurpose: "",
    gratuity: null,
    fixedAmount: false,
    quantity: 1,
  },
});

export const PaymentLinkRoutes = () => (
  <ErrorBoundary
    fallback={({ error }) => {
      if (/ChunkLoadError/.test(error.name)) {
        window.location.reload();
      }
      // If there's no ChunkLoadError, just render the component as usual
      return <Routes />;
    }}
  >
    <Routes />
  </ErrorBoundary>
);

export const Routes = () => (
  <>
    {/* @ts-ignore */}
    <Suspense fallback={LoaderPaymentLink}>
      <StateMachineProvider>
        <Router>
          {/* <PaymentWidgetInline path="inline-widgetpay/:slug" /> */}
          <Pay path="/pay/:slug" />
          <PayStep2 path="pay/:slug/step2" />
          <PayStep2 path="pay/:slug/details" />
          <PayStep3 path="pay/:slug/step3" />
          <PayStep3 path="pay/:slug/payment-method" />
          <PaySuccess path="mission-complete" />
          <PayProcessing path="processing" />
          <Inactive path="link-inactive" />
          <NotFound default />
        </Router>
      </StateMachineProvider>
    </Suspense>
  </>
);
